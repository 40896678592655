import React from "react"
import { BackgroundLayout } from "layouts"
import { Forms } from "components"
import bg from "../../images/portrait-top-mount-taranaki-new-zealand.png"

const ResetPassword = () => {
  const backgroundLayoutProps: IBackgroundLayoutProps = {
    background: bg,
    backgroundName: "mount taranaki new zealand",
  }

  return (
    <BackgroundLayout {...backgroundLayoutProps}>
      <Forms.ResetPassword />
    </BackgroundLayout>
  )
}

export default ResetPassword
